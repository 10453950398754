import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api, downloadFile } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import {
    Box, Tooltip, Stepper, Step, StepLabel,
    ListItemButton, ListItemText, List, ListItem, Autocomplete, TextField
} from '@mui/material'
import { CircularProgress } from '@mui/material';
import timeoutList from '../../Utils/TimeoutList';
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import { getSession } from '../../../contexts/auth';
import apptheme from '../../theme/default';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { defaultBackend } from '../../../services/api';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { generateReport } from '../Schedule/Edit-schedule-modal';
import { generateProposal } from '../Proposal/Edit-proposal-modal';
import PostPayment from './postpayment';
import { maskedTable } from '../../Utils/MaskedInput';
import { number2dayweek, actionBar } from '../../Utils/dayjsptbrhelper';
import IntegerOnly from '../../Utils/IntegerOnly';
import ShowDisponibility from '../Schedule/show-disponibility';
const url = '/order/serviceorder/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const getTotalValue = async (orderid, setState) => {
    const res = await api.get(url + `${orderid}/proposal_value/`)
    if (res.status === 200) {
        const [total_value, prepayment, teams] = res.data
        setState({ loaded: true, total_value: total_value, prepayment: prepayment, teams: teams })
    }

}

const loadProposalRow = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        res.data.teams = res.data.teams_serialized
        setState({ ...res.data, step: 1, loaded: true, saved: true })
    }
    setLoader(false)
}

const cancel = async (id, getData, setLoader, setState, message = '') => {
    setLoader(true)
    const res = await api.post(url + `${id}/cancel/`, { message: message })
    setLoader(false)
    if (res.status === 200) {
        toast.success(`Ordem de serviço cancelada com sucesso`)
        getData()
        res.data.teams = res.data.teams_serialized
        setState({ ...res.data, step: 1 })
    }
}

const validateTeam = async (date, team, duration, pk = 0, setState) => {
    const res = await api.post(url + `checkavailability/`, { date: date, team: team, duration: duration, this_object: pk })
    if (res.status === 200) {
        if (res.data === true) {
            setState({ can_schedule: res.data })
            toast.success('Horário disponível.')
            return
        }
    }
    toast.error('Horário indisponível para a equipe selecionada.')
}

const submit = async (data, getData, setLoader, setState) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)

    if (res.status === 201 || res.status === 200) {
        toast.success(`Ordem de serviço ${res.status === 201 ? 'adicionada' : 'editada'} com sucesso`)
        getData()
        res.data.teams = res.data.teams_serialized
        setState({ ...res.data, step: 1 })
        setLoader(false)
        return
    }
    toast.error(`Ordem de serviço não pôde ser ${!data.id ? 'adicionada' : 'editada.'} Motivo: ${res.data}`)
    setLoader(false)
}

const generateServiceOrder = async (id, setLoader) => {
    setLoader(true)
    const res = await api.post(url + `${id}/generate/`)
    if (res.status === 200) {
        downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
    }
    setLoader(false)
}

const generateDocument = async (id, docid, setLoader) => {
    setLoader(true)
    const res = await api.post(url + `${id}/generatedocument/${docid}/`)
    if (res.status === 200) {
        downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
    }
    setLoader(false)
}

const disponibilityVerify = async (state, setLoader, setState) => {
    const date = dayjs(state.datetime)
    const duration = state.duration
    const nextDate = date.add(duration, 'minutes')   

    setLoader(true)
    const res = await api.post('/order/serviceorder/disponibilityVerify/', {
        order_id: state.order_id || state.order.id,
        teams: state.teams,
        newDate: date.format('YYYY-MM-DD HH:mmZ'), 
        nextDate: nextDate.format('YYYY-MM-DD HH:mmZ'),
        datetime__gte: date.format('YYYY-MM-DD 00:00Z'), 
        datetime__lt: date.add(1, 'day').format('YYYY-MM-DD 00:00Z'), 
        data_only: true 
    })
    setLoader(false)

    if (res.status === 406) {
        setState({step: 0})
        toast.error('Horário indisponivel')
    }
    
    if (res.status === 200) {
        setState({step: state.step + 1})
        toast.success('Horário disponivel')
    }
}

const generateSteps = (state, setState, user, setLoader) => {

    return [
        {
            label: 'Agendamento', component: (
                <>
                    <Box className='item-profile-row'>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <MobileDateTimePicker
                                size='small'
                                label='Data e hora'
                                value={dayjs(state.datetime)}
                                format='DD/MM/YYYY HH:mm'
                                sx={{ flex: 1 }}
                                onChange={(e) => { setState({ 'datetime': e }) }}
                                slotProps={{ textField: { size: 'small' } }}
                                slots={{ toolbar: actionBar }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                            <TextField
                                size='small'
                                sx={{ flex: .3 }}
                                label='Duração (minutos)'
                                value={state.duration}
                                onChange={(e) => setState({ duration: IntegerOnly(e.target.value, 0, 9999) })}
                            />
                        </Box>
                    </Box>
                    <Autocomplete
                        multiple
                        sx={{ flex: .8 }}
                        id={'autocompletebox-team'}
                        value={state.teams}
                        options={state.loaded_teams.filter(loaded => !state.teams.map(e => e.id).includes(loaded.id))}
                        onChange={(e, selectedTeam) => { setState({ teams: selectedTeam }) }}
                        autoHighlight
                        handleHomeEndKeys
                        clearText='Limpar'
                        size="small"
                        noOptionsText='Digite para pesquisar equipes'
                        loading={state.smallLoading}
                        onOpen={() => state.loaded_teams.length === 0 && timeoutList('', 'order/serviceorder/team/', 'loaded_teams', 'name', setState)}
                        onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/serviceorder/team/', 'loaded_teams', 'name', setState) }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                id={'autocompleteinput'}
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Equipe"
                                size="small"
                                fullWidth={true}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        }
                    />
                </>
            )
        },
        {
            label: 'Ações', component: (
                <>
                    {(user.permissions.view_schedule && state.order?.schedule) && <ColorButton
                        onClick={() => generateReport(state.order.schedule, setLoader)}
                    >
                        Ver Visita
                    </ColorButton>}
                    {(user.permissions.view_proposal) && <ColorButton
                        onClick={() => generateProposal(state.order.proposal, setLoader)}
                    >
                        Ver Proposta
                    </ColorButton>}
                    {(user.permissions.view_serviceorder && state.state !== '') && <ColorButton
                        onClick={() => generateServiceOrder(state.order.service_order, setLoader)}
                    >
                        Ver Ordem de serviço
                    </ColorButton>}
                    {(user.permissions.view_serviceorder && state.state !== '') && state.documents.map(each => <ColorButton
                        onClick={() => generateDocument(state.id, each.id, setLoader)}
                    >
                        Ver {each.name}
                    </ColorButton>)}
                    <List className='proposal-resume-list'>
                        <ListItemButton><ListItemText primary='Cliente' secondary={state.client?.label || state.client_name} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Valor a pagar' secondary={maskedTable['value'](state.total_value)} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Confimar Data' secondary={dayjs(state.datetime).toDate().toLocaleString()} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Equipes' secondary={state.teams.map(each => each.label).join(', ')} /></ListItemButton>
                        {state.state === 'CANCELLED' && <ListItemButton><ListItemText primary='Motivo do cancelamento' secondary={state.cancel_register} /></ListItemButton>}
                        {state.paid_value ? <>
                            <ListItemButton><ListItemText primary='Valor pago' secondary={maskedTable['value'](state.paid_value)} /></ListItemButton>
                        </> : null}
                        {state.postpayment_data?.length > 0 ? <>
                            <ListItemButton>
                                <ListItemText
                                    primary='Pagamento'
                                    secondary={(<List>
                                        {state.postpayment_data?.map(each => (
                                            <Box className='nopadding' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <ListItem className='nopadding'>{each.parcels || each.data.parcel_count || 1}x {each.label}</ListItem>
                                                <ListItem sx={{ flex: 1, whiteSpace: 'pre' }} className='nopadding'> {maskedTable['value'](each.value)}</ListItem>
                                            </Box>))}
                                    </List>)} />
                            </ListItemButton>
                            <ListItemButton><ListItemText primary='Adicional' secondary={maskedTable['value'](state.additional)} /></ListItemButton>
                            <ListItemButton><ListItemText primary='Desconto' secondary={maskedTable['value'](state.discount)} /></ListItemButton>
                            {state.observation_register && <ListItemButton><ListItemText primary='Observação' secondary={state.observation_register} /></ListItemButton>}
                        </> : null}

                    </List>
                </>
            )
        }
    ]
}

const EditServiceOrderModal = ({ data, handleClose, reloadData }) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        order: null,
        order_id: null,
        datetime: '',
        duration: 0,
        client: {},
        teams: [],
        loaded_teams: [],
        loaded: false,
        step: 0,
        confirmSubmit: null,
        state: '',
        paid_value: '',
        total_value: '',
        additional: 0,
        discount: 0,
        showpostpayment: null,
        postpayments: [],
        postpayment_data: [],
        documents: [],
        proposal: {},
        saved: false,
        cancel_register: '',
        observation_register: '',
        //can_schedule: false,
        showDisponibility: false,
        ...data
    })

    useEffect(() => {
        if (data.id)
            loadProposalRow(data.id, setState, setLoader)
        else {
            getTotalValue(data.order.id, setState)
        }

    }, [setLoader, data.id, data.order?.id])

    // useEffect(() => {
    //     if (state.datetime && state.teams && state.duration) {
    //         clearTimeout(timeout)
    //         timeout = setTimeout(() => validateTeam(dayjs(state.datetime), state.teams, state.duration, state.id, setState), 500)
    //     }

    // }, [state.datetime, state.teams, state.duration, state.id])

    const validData = validateData(state)

    const stepsArray = generateSteps(state, setState, user, setLoader)
    const maxStep = stepsArray.length - 1

    return (
        <>
            {state.showDisponibility &&
                <ShowDisponibility
                    id={state.id}
                    initialDay={state.datetime}
                    duration={state.duration}
                    submitDate={(datetime, duration) => { setState({ datetime: datetime, duration: duration, showDisponibility: false }) }}
                    handleClose={() => setState({ showDisponibility: false })}
                />
            }
            {state.confirmSubmit &&
                <ConfirmationDialog
                    title={state.confirmSubmit.title}
                    handleClose={() => setState({ confirmSubmit: null })}
                    onConfirm={state.confirmSubmit.function}
                    okButton={state.confirmSubmit.okButton}
                    content={state.confirmSubmit.content}
                    message={state.confirmSubmit.message}
                />}
            {state.loaded && <DefaultModal
                title={
                    `${getTitle(state)} Ordem de serviço`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Stepper activeStep={state.step}>
                            {stepsArray.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel sx={{
                                        '.MuiStepIcon-root.Mui-active': { color: apptheme.primaryColor },
                                        '.MuiStepIcon-root.Mui-completed': { color: apptheme.primaryColor }
                                    }}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className='item-content-modal noborder' sx={{ paddingTop: '1rem' }}>
                            {stepsArray[state.step].component}
                        </Box>
                    </>
                }
                action={
                    <>

                        <Box className='action-left-options'>
                            {(user.permissions.view_client && state.step === 0) &&
                                <ColorButton
                                    onClick={() => { setState({ showDisponibility: true }) }}
                                >
                                    Verificar disponibilidade
                                </ColorButton>}
                            {(state.step === maxStep && state.state === 'OPEN' && user.permissions.change_serviceorder) && <ColorButton
                                onClick={() => setState({
                                    confirmSubmit: {
                                        function: (message) => { cancel(state.id, reloadData, setLoader, setState, message); setState({ confirmSubmit: null }) },
                                        title: 'Confirmar ação.',
                                        content: 'Deseja cancelar a ordem de serviço?',
                                        okButton: 'Sim',
                                        message: true
                                    }
                                })}
                                disabled={validData}>
                                Cancelar
                            </ColorButton>}
                        </Box>
                        <Box className='action-right-options'>
                            {(state.step !== 0 && ['', 'OPEN'].includes(state.state)) && <ColorButton
                                onClick={() => setState({ step: state.step - 1, saved: false })}
                            >
                                Anterior
                            </ColorButton>}

                            {!state.saved && <Tooltip placement="left" title={validData}>
                                <Box>
                                    {state.step !== maxStep && <ColorButton
                                        onClick={() => {disponibilityVerify(state, setLoader, setState)}}
                                        disabled={validData}>
                                        Próximo
                                    </ColorButton>}
                                </Box>
                                <Box>
                                    {(state.step === maxStep && ['', 'OPEN'].includes(state.state) && user.permissions.add_serviceorder && !state.saved) && <ColorButton
                                        onClick={() => setState({
                                            confirmSubmit: {
                                                function: () => { submit(state, reloadData, setLoader, setState); setState({ confirmSubmit: null, saved: true }) },
                                                title: 'Confirmar ação.',
                                                content: 'Deseja salvar a ordem de serviço?',
                                                okButton: 'Sim'
                                            }
                                        })}
                                        disabled={validData}>
                                        Salvar
                                    </ColorButton>}
                                </Box>
                            </Tooltip>
                            }

                            {(state.step === maxStep && state.state === 'OPEN' && user.permissions.change_serviceorder && state.saved) && <ColorButton
                                sx={{ width: '20rem' }}
                                onClick={() => setState({
                                    showpostpayment: {}
                                })}
                                disabled={validData}>
                                Ordem de serviço realizada
                            </ColorButton>}
                        </Box>
                    </>

                }
            />}
            {state.showpostpayment && <PostPayment parent={state} setParent={setState} reloadData={reloadData} />}
        </>
    )
}

export default EditServiceOrderModal