import React, { useReducer, useContext } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import { Box, Tooltip, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import IntegerOnly from '../../Utils/IntegerOnly';
import { getSession } from '../../../contexts/auth';
import MegaAutoComplete from '../../Utils/megaautocomplete';

const url = '/payment/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const submit = async (data, getData, setLoader, handleClose) => {

    setLoader(true)
    let res = null

    if (data.id)
        res = await api.patch(`${url}${data.id}/`, data)
    else
        res = await api.post(url, data)
    if (res.status === 201 || res.status === 200) {
        toast.success(`Forma de pagamento ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        handleClose()
        setLoader(false)
        return
    }
    toast.error(`Forma de pagamento não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const EditPaymentModal = ({ data, handleClose, reloadData }) => {

    const finance = getSession()?.finance

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        name: '',
        parcel_fix: true,
        first_parcel: true,
        parcel_count: 1,
        num_days: 1,
        upfront: true,
        payment_method: '',
        ...(finance ? { cashaccount: '' } : {}),
        ...data,
        id: data.id ? data.id : 0
    })

    const validData = validateData(state)

    return (
        <>
            <DefaultModal
                title={
                    `${getTitle(state)} Forma de pagamento`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Box className='item-content-modal noborder' >
                            <Box className='item-profile-row'>
                                <TextField
                                    id="name"
                                    type="text"
                                    label="Nome"
                                    value={state.name}
                                    onChange={(e) => { setState({ 'name': e.target.value }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                    sx={{ flex: '.68 !important' }}
                                />

                                {<FormControl sx={{ minWidth: 120 }} size='small' >
                                    <InputLabel id="select-type-label">Métodos</InputLabel>
                                    <Select
                                        labelId="select-type-label"
                                        id="select-type"
                                        label="Métodos"
                                        value={state.payment_method}
                                        onChange={(e) => { setState({ 'payment_method': e.target.value }) }}
                                    >
                                        <MenuItem value={'Dinheiro'}>Dinheiro</MenuItem>
                                        <MenuItem value={'Pix'}>Pix</MenuItem>
                                        <MenuItem value={'Boleto'}>Boleto</MenuItem>
                                        <MenuItem value={'Cartão'}>Cartão</MenuItem>

                                    </Select>
                                </FormControl>}
                                <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre', flex: '.32 !important' }}>
                                    <ColorButton reverse={!state.upfront} onClick={() => setState(
                                        {
                                            upfront: true,
                                            parcel_count: 1,
                                            num_days: 1,
                                            parcel_fix: true
                                        }
                                    )}>À vista</ColorButton>
                                    <ColorButton reverse={state.upfront} onClick={() => setState({ upfront: false })}>À Prazo</ColorButton>
                                </Box>
                            </Box>
                            <Box className='item-profile-row'>
                                <TextField
                                    id="parcel_count"
                                    type="text"
                                    label="Parcelas"
                                    disabled={state.upfront}
                                    value={state.parcel_count}
                                    onChange={(e) => { setState({ 'parcel_count': IntegerOnly(e.target.value, 1, 100) }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                                <Box className='user-activity-buttons' sx={{ whiteSpace: 'pre' }}>
                                    <ColorButton disabled={state.upfront} reverse={!state.parcel_fix} onClick={() => setState({ parcel_fix: true })}>N° Fixo</ColorButton>
                                    <ColorButton disabled={state.upfront} reverse={state.parcel_fix} onClick={() => setState({ parcel_fix: false })}>N° Máximo</ColorButton>
                                </Box>
                                <TextField
                                    id="num_days"
                                    type="text"
                                    label="N° Dias"
                                    disabled={state.upfront}
                                    value={state.num_days}
                                    onChange={(e) => { setState({ 'num_days': IntegerOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    size='small'
                                    inputProps={{
                                        maxLength: 50
                                    }}
                                />
                            </Box>
                            <Box className='item-profile-row'>
                                <Box className='payment-switch-buttons' sx={{ whiteSpace: 'pre', flex: '.2 !important' }}>
                                    <ColorButton reverse={!state.first_parcel} onClick={() => setState({ first_parcel: true })}>Entrada</ColorButton>
                                    <ColorButton reverse={state.first_parcel} onClick={() => setState({ first_parcel: false })}>S/ Entrada</ColorButton>
                                </Box>
                                {finance &&
                                    <MegaAutoComplete
                                        sx={{ flex: '.8 !important' }}
                                        label={'Conta caixa'}
                                        noOptionsText={'Digite para procurar contas caixa'}
                                        value={state.cashaccount}
                                        onChange={(data) => setState({ cashaccount: data })}
                                        integrated
                                        integrated_path='/finance/'
                                        timeoutUrl='/api/cashaccount/'
                                        timeoutParam='name'
                                    />}
                            </Box>
                        </Box>
                    </>
                }
                action={
                    <>
                        <Tooltip placement="left" title={validData}>
                            <Box>
                                <ColorButton
                                    onClick={() => submit(state, reloadData, setLoader, handleClose)}
                                    disabled={validData}
                                >Salvar
                                </ColorButton>
                            </Box>
                        </Tooltip>
                    </>
                }
            />
        </>
    )
}

export default EditPaymentModal