import React, { useContext, useReducer, useEffect, useState } from "react";

import { Box, Tooltip } from "@mui/material";
import { api } from "../../../services/api";
import toast from "react-hot-toast";
//import { getSession } from "../../../contexts/auth";
import { lockedReducer } from "../../Utils/reducers";
import { externalComponent } from "../../AppRoutes";
import ColorButton from "../../Buttons/ColorButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import ReplyIcon from "@mui/icons-material/Reply";
import dayjs from "dayjs";
import { number2dayweek } from "../../Utils/dayjsptbrhelper";
import MegaAutoComplete from "../../Utils/megaautocomplete";
import ReportTable from "../report_table";
import { maskedTable } from "../../Utils/MaskedInput";
import PrintToPDF from "../../Utils/Print2Pdf";

const searchURL = '/reportsaux/'
const url = "/reports/serviceorderfaturated/";
const url_opencancelled = "/reports/serviceorderopencancelled/"

const generateColumns = () => {
  return [
    { field: 'order_id', headerName: 'Número', width: '10rem', count: true },
    {
      field: 'created_at', headerName: 'Emissão', width: '7rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
    { field: 'client', headerName: 'Cliente', width: '15rem' },
    {
      field: 'desc_acres', headerName: 'Desc/Acrés', width: '10rem', sum: true,
      valueGetter: ({ value }) => maskedTable.value(value)
    },
    {
      field: 'paid_value', headerName: 'Total', width: '10rem', sum: true,
      valueGetter: ({ value }) => maskedTable.value(value)
    },
    {
      field: 'postpayment_date', headerName: 'Faturado Em', width: '7rem',
      valueGetter: ({ value }) => dayjs(value).format('DD/MM/YYYY')
    },
  ]
}
const getPaymentMethod = async (paylistSetState, setLoader) => {
  setLoader(true)
  const res = await api.get('/payment/')
  const result = res.data.results.map(item => (
    {
      'id': item.id,
      'payment_method': item.payment_method
    }
  )
  )
  setLoader(false)
  paylistSetState(result)

}
const generate = async (state, setState, setLoader, paylistState, paylistSetState) => {
  setLoader(true)
  const { rows, teams, columns, open, cancelled, ...data } = state
  toast.success('Seu relatório está sendo gerado, aguarde alguns segundos para o seu processamento')
  const promise = api.post(url, {
    ...data, teams__in: teams?.id ? [teams?.id] : null,
    postpayment_date__gte: data.postpayment_date__gte.set('hour', 0).set('seconds', 0),
    postpayment_date__lte: data.postpayment_date__lte.set('hour', 23).set('seconds', 59)
  })
  const opencancelledpromise = api.post(url_opencancelled, {
    ...data, teams__in: teams?.id ? [teams?.id] : null,
    postpayment_date__gte: data.postpayment_date__gte.set('hour', 0).set('seconds', 0),
    postpayment_date__lte: data.postpayment_date__lte.set('hour', 23).set('seconds', 59)
  })

  const [res, resopencancelled] = await Promise.all([promise, opencancelledpromise])

  if (res.status !== 200) {
    toast.error(`Falha ao gerar o relatório. Motivo: ${res.error}`)
  }
  else {
    const columns = generateColumns()
    const newFields = []
    const newdata = res.data.filter(data => data.state === 'DONE').map(data => {

      const { postpayment_data, ...row } = data
      postpayment_data?.forEach(payment => {
        paylistState.map(item => {
          if (item.id === payment['id']) payment['payment_method'] = item.payment_method
        })
        row[payment.payment_method] = payment.value
        if (!newFields.includes(payment.payment_method)) newFields.push(payment.payment_method)
      })

      return row
    })
    newFields.forEach(field => columns.push({
      field: field, headerName: field,
      valueGetter: ({ value }) => maskedTable.value(value),
      width: '10rem',
      sum: true,
    }))
    const open = resopencancelled.data.filter(data => data.state === 'OPEN')
    const cancelled = resopencancelled.data.filter(data => data.state === 'CANCELLED')
    setState({ rows: newdata, columns: columns, open: open, cancelled: cancelled })
  }
  setLoader(false)
}

const FaturatedServiceOrder = () => {
  const { setLoader } = useContext(externalComponent);

  const [state, setState] = useReducer(lockedReducer, {
    rows: [],
    columns: [],
    open: [],
    cancelled: [],
    postpayment_date__gte: dayjs(),
    postpayment_date__lte: dayjs(),
    order__client: '',
    order__seller: '',
    teams: '',
  });

  const [paylistState, paylistSetState] = useState([])

  useEffect(() => {
    setState({
      postpayment_date__gte: dayjs().subtract(1, 'month'),
      postpayment_date__lte: dayjs()
    })
    getPaymentMethod(paylistSetState, setLoader)
  }, [])

  return (
    <>
      <Box className="information-table">
        <Box className='header'>
          <Box className='left'>
            <Box className='item-profile-row' sx={{ gap: '1rem', display: 'flex', flexWrap: 'wrap' }}>
              <Tooltip title='Voltar'>
                <ColorButton
                  className='profile-save-button minimized'
                  onClick={() => window.backtobase()}
                >
                  <ReplyIcon />
                </ColorButton>
              </Tooltip>

              <MobileDatePicker
                size='small'
                label='Data de inicio'
                value={dayjs(state.postpayment_date__gte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'postpayment_date__gte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />

              <MobileDatePicker
                size='small'
                label='Data de fim'
                value={dayjs(state.postpayment_date__lte)}
                format='DD/MM/YYYY'
                sx={{ background: 'white' }}
                onChange={(e) => { setState({ 'postpayment_date__lte': e }) }}
                slotProps={{ textField: { size: 'small' } }}
                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                ampm={false}
              />
              <MegaAutoComplete
                value={state.order__client}
                onChange={(data) => setState({ order__client: data })}
                noOptionsText={`Digite para procurar clientes`}
                timeoutUrl={searchURL + 'clients/'}
                timeoutParam={'name'}
                label={'Clientes'}
              />
              <MegaAutoComplete
                value={state.order__seller}
                onChange={(data) => setState({ order__seller: data })}
                noOptionsText={`Digite para procurar Vendedores`}
                timeoutUrl={searchURL + 'sellers/'}
                timeoutParam={'name'}
                label={'Vendedor'}
              />
              <MegaAutoComplete
                value={state.teams}
                onChange={(data) => setState({ teams: data })}
                noOptionsText={`Digite para procurar Equipes`}
                timeoutUrl={searchURL + 'teams/'}
                timeoutParam={'name'}
                label={'Equipe'}
              />
              <ColorButton
                sx={{ height: '2.5rem' }}
                onClick={() => { generate(state, setState, setLoader, paylistState, paylistSetState) }}
              >Gerar relatório</ColorButton>
              {state.rows.length > 0 &&
                <ColorButton onClick={() => { PrintToPDF('report-main-screen', null, 'Relatório') }}
                  sx={{ height: '2.5rem' }}>
                  Imprimir relatório
                </ColorButton>}
            </Box>

          </Box>
        </Box>
        <Box id='report-main-screen' className="report-main-screen">
          <ReportTable
            title={`Relação de pedidos faturados entre ${state.postpayment_date__gte.format('DD/MM/YYYY')} e ${state.postpayment_date__lte.format('DD/MM/YYYY')} - Cliente - ${state?.order__client?.label || ''} - Vendedor - ${state?.order__seller?.label || ''} - Time - ${state?.teams?.label || ''}`}
            rows={state.rows}
            columns={state.columns}
            extrainfo={
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem' }}>
                <span style={{ borderBottom: '2px solid black', maxWidth: '15rem' }}>Posição de outros pedidos:</span>
                <span style={{ paddingLeft: '5rem' }}>
                  Aberto:  {state.open.length > 0 ? `${state.open.length} Pedido(s)
                 Totalizando ${maskedTable.value(state.open.map(e => Number(e.total_value)).reduce((a, b) => a + b, 0))}` : '--'}
                </span>
                <span style={{ paddingLeft: '5rem' }}>
                  Cancelado:   {state.cancelled.length > 0 ? `${state.cancelled.length} Pedido(s) 
                Totalizando ${maskedTable.value(state.cancelled.map(e => Number(e.total_value)).reduce((a, b) => a + b, 0))}` : '--'}
                </span>
              </Box>
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default FaturatedServiceOrder;
