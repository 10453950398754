import React, { useReducer, useContext, useEffect } from 'react'
import { lockedReducer } from '../../Utils/reducers'
import { api, downloadFile } from '../../../services/api'
import ColorButton from '../../Buttons/ColorButton';
import DefaultModal from '../../Utils/DefaultModal';
import toast from 'react-hot-toast'
import {
    Box, Tooltip, TextField, Autocomplete, Stepper, Step, StepLabel,
    ListItemButton, ListItemText, List,
    duration
} from '@mui/material'
import validateData from './validateData';
import { externalComponent } from '../../AppRoutes';
import IntegerOnly from '../../Utils/IntegerOnly';
import timeoutList from '../../Utils/TimeoutList';
import EditClientModal from '../../Clients/Edit-client-modal';
import { getSession } from '../../../contexts/auth';
import CircularProgress from '@mui/material/CircularProgress';
import apptheme from '../../theme/default';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditTeamModal from '../../Settings/Teams/Edit-team-modal';
import ConfirmationDialog from '../../Utils/ConfirmationDialog';
import { defaultBackend } from '../../../services/api';
import { number2dayweek, actionBar } from '../../Utils/dayjsptbrhelper';
import ShowDisponibility from './show-disponibility';

const url = '/order/schedule/'

const getTitle = (data) => {
    if (data?.id)
        return 'Editar'
    return 'Adicionar'
}

const updateData = (id, res, setState) => {
    let step = 0
    const client = res.data.client ? { label: res.data.client.name, id: res.data.client.id, data: res.data.client } : ''
    const datetime = res.data.datetime ? dayjs(res.data.datetime) : ''
    const workers_amount = res.data.workers_amount || 0
    const team = res.data.team ? { label: res.data.team.name, id: res.data.team.id, data: res.data.team } : ''
    if (client)
        step += 1
    if (team && datetime)
        step += 1

    setState({
        id: id,
        client: client,
        datetime: datetime,
        workers_amount: workers_amount,
        team: team,
        step: step,
        order: res.data.order,
        loaded: true,
        responsable: res.data.responsable,
        visitReport: res.data.visitReport,
        description: res.data.description,
        state: res.data.state,
        cancel_register: res.data.cancel_register,
        duration: res.data.duration
    })
}

const loadScheduleRow = async (id, setState, setLoader) => {
    setLoader(true)
    const res = await api.get(url + `${id}/`)
    if (res.status === 200) {
        updateData(id, res, setState)
    }
    setLoader(false)
}

const createData = (state, draft) => {
    return {
        id: state.id || null,
        client: state.client || null,
        datetime: state.datetime || null,
        team: state.team || null,
        workers_amount: state.workers_amount || 0,
        responsable: state.responsable,
        draft: draft,
        description: state.description,
        duration: state.duration,
    }
}

const cancelSchedule = async (state, setState, setLoader, getData, message = '') => {
    setLoader(true)
    const res = await api.post(url + `${state.id}/cancel/`, { message: message })
    if (res.status === 200) {
        toast.success(`Agendamento cancelado com sucesso`)
        getData()
        updateData(state.id, res, setState)
    }
    setLoader(false)
}

const submit = async (data, getData, setLoader, handleClose, setState, draft) => {

    setLoader(true)
    let res = null

    const event = draft ? 'Rascunho' : 'Agendamento'

    const payload = createData(data, draft)

    if (payload.id)
        res = await api.patch(`${url}${payload.id}/`, payload)
    else
        res = await api.post(url, payload)
    if (res.status === 201 || res.status === 200) {
        toast.success(`${event} ${res.status === 201 ? 'adicionado' : 'editado'} com sucesso`)
        getData()
        updateData(res.data.id, res, setState)
        setLoader(false)
        return
    }
    toast.error(`${event} não pôde ser ${!data.id ? 'adicionado' : 'editado.'} Motivo: ${res.data}`)
    setLoader(false)
}

const initProposal = async (state, openProposal) => {
    openProposal({ client: state.client, order_id: state.order.id, order: state.order, schedule_responsable: state.responsable })
}

export const generateReport = async (id, setLoader) => {
    setLoader(true)
    const res = await api.post(url + `${id}/generate/`)
    if (res.status === 200) {
        await downloadFile(defaultBackend.replace('api/', '') + res.data + '/')
    }
    setLoader(false)
}

const validateTeam = async (date, team, duration, pk = 0, setState) => {
    const res = await api.post(url + `checkavailability/`, { date: date, team: team, duration: duration, this_object: pk })
    if (res.status === 200) {
        if (res.data === true) {
            setState({ can_schedule: res.data })
            toast.success('Horário disponível.')
            return
        }
    }
    toast.error('Horário indisponível para a equipe selecionada.')
}

const disponibilityVerify = async (state, setLoader, setState) => {
    const date = state.datetime
    const duration = state.duration
    const nextDate =  date ? date.add(duration, 'minutes') : null  
    const team = state.team['id'] || ''

    setLoader(true)
    const res = await api.post('/order/schedule/disponibilityVerify/', {
        order_id: state.order.id,
        team: team,
        newDate:  date ? date.format('YYYY-MM-DD HH:mmZ') : null, 
        nextDate: nextDate ? nextDate.format('YYYY-MM-DD HH:mmZ') : null,
        datetime__gte: date ? date.format('YYYY-MM-DD 00:00Z') : null, 
        datetime__lt: date ? date.add(1, 'day').format('YYYY-MM-DD 00:00Z') : null, 
        data_only: true 
    })
    setLoader(false)
    
    if (res.status === 200) {
        setState({step: state.step + 1})
        toast.success('Data disponivel')
        return
    }
    
    setState({step: 1})
    toast.error('Horário indisponivel')

}

const generateSteps = (state, setState, permissions, setLoader) => {

    return [
        {
            label: 'Cliente', component: (
                <Box className='item-profile-row' sx={{ flexDirection: 'column' }}>
                    <Autocomplete
                        disabled={state.state !== ''}
                        sx={{ flex: 1 }}
                        id={'autocompletebox-client'}
                        value={state.client}
                        options={state.loaded_clients}
                        onChange={(e, selectedClient) => { setState({ client: selectedClient }) }}
                        autoHighlight
                        handleHomeEndKeys
                        clearText='Limpar'
                        noOptionsText='Digite para pesquisar clientes'
                        loading={state.smallLoading}
                        onOpen={() => state.loaded_clients.length === 0 && timeoutList('', 'order/schedule/client/', 'loaded_clients', 'name', setState)}
                        onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/schedule/client/', 'loaded_clients', 'name', setState) }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                id={'autocompleteinput'}
                                type="text"
                                sx={{ backgroundColor: 'white' }}
                                label="Cliente"
                                size="small"
                                fullWidth={true}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        }
                    />
                    <Box className='item-content-modal noborder nopadding' >
                        <EditClientModal
                            key={`client-modal-key${state.client?.data?.id}${state?.edit}`}
                            data={state.client?.data}
                            readOnly
                            handleClose={() => { }}
                            reloadData={() => { }}
                        />
                    </Box>
                </Box>
            )
        },
        {
            label: 'Planejamento', component: (
                <>
                    <Box className='item-profile-row'>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <MobileDateTimePicker
                                size='small'
                                label='Data e hora'
                                value={dayjs(state.datetime)}
                                format='DD/MM/YYYY HH:mm'
                                sx={{ flex: .7 }}
                                onChange={(e) => { setState({ 'datetime': e })}}
                                slotProps={{ textField: { size: 'small' } }}
                                slots={{ toolbar: actionBar }}
                                dayOfWeekFormatter={(n, date) => number2dayweek[date.$W]}
                                ampm={false}
                            />
                            <TextField
                                size='small'
                                sx={{ flex: .3 }}
                                label='Duração (minutos)'
                                value={state.duration}
                                onChange={(e) => setState({ duration: IntegerOnly(e.target.value, 0, 9999) })}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Autocomplete
                            sx={{ flex: 1 }}
                            id={'autocompletebox-responsable'}
                            value={state.responsable}
                            options={state.loaded_responsable.filter(each => state.responsable?.id !== each.id)}
                            onChange={(e, selectedResponsable) => { setState({ responsable: selectedResponsable }) }}
                            autoHighlight
                            handleHomeEndKeys
                            clearText='Limpar'
                            noOptionsText='Digite para pesquisar Vendedor'
                            onOpen={() => state.loaded_responsable.length === 0 && timeoutList('', 'order/schedule/responsable/', 'loaded_responsable', 'first_name', setState)}
                            loading={state.smallLoading}
                            ChipProps={{ size: 'small' }}
                            filterSelectedOptions
                            onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/schedule/responsable/', 'loaded_responsable', 'first_name', setState) }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={'autocompleteinput'}
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Vendedor"
                                    size="small"
                                    fullWidth={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            }
                        />
                    </Box >
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Autocomplete
                            sx={{ flex: .8 }}
                            id={'autocompletebox-team'}
                            value={state.team}
                            options={state.loaded_teams}
                            onChange={(e, selectedTeam) => { setState({ team: selectedTeam, workers_amount: selectedTeam?.data?.members?.length || 0 }) }}
                            autoHighlight
                            handleHomeEndKeys
                            clearText='Limpar'
                            noOptionsText='Digite para pesquisar equipes'
                            loading={state.smallLoading}
                            onOpen={() => state.loaded_teams.length === 0 && timeoutList('', 'order/schedule/team/', 'loaded_teams', 'name', setState)}
                            onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/schedule/team/', 'loaded_teams', 'name', setState) }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    id={'autocompleteinput'}
                                    type="text"
                                    sx={{ backgroundColor: 'white' }}
                                    label="Equipe"
                                    size="small"
                                    fullWidth={true}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            }
                        />
                        <TextField
                            size='small'
                            sx={{ flex: .2 }}
                            label='Funcionários'
                            value={state.workers_amount}
                            onChange={(e) => setState({ workers_amount: IntegerOnly(e.target.value, 0, 9999) })}
                        />
                    </Box>
                    <Box className='item-content-modal noborder nopadding' >
                        <EditTeamModal
                            key={`team-modal-key${state.team?.data?.id}${state?.edit}`}
                            data={state.team?.data}
                            readOnly
                            handleClose={() => { }}
                            reloadData={() => { }}
                        />
                    </Box>
                    <TextField
                        multiline
                        maxRows={4}
                        size='small'
                        value={state.description}
                        onChange={(e) => setState({ description: e.target.value })}
                        inputProps={{ maxLength: 5000 }}
                        label='Situação Problema'
                    />
                </>
            )
        },
        {
            label: 'Ações', component: (
                <>
                    {(!['', 'OPEN'].includes(state?.state)) && permissions.view_schedule && <ColorButton
                        onClick={() => generateReport(state.id, setLoader)}
                        sx={{}}>
                        Ver relatório de visita
                    </ColorButton>}

                    <List className='proposal-resume-list'>
                        <ListItemButton><ListItemText primary='Cliente' secondary={state.client?.label} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Data' secondary={dayjs(state.datetime).toDate().toLocaleString()} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Vendedor' secondary={state.responsable?.label} /></ListItemButton>
                        <ListItemButton><ListItemText primary='Equipe' secondary={state.team?.label} /></ListItemButton>
                        {state.state === 'CANCELLED' && <ListItemButton><ListItemText primary='Motivo do cancelamento' secondary={state.cancel_register} /></ListItemButton>}
                    </List>
                </>
            )
        },
    ]
}

const EditScheduleModal = ({ data, handleClose, reloadData, openProposal }) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        id: 0,
        client: '',
        datetime: '',
        duration: 0,
        team: '',
        workers_amount: 0,
        smallLoading: false,
        responsable: null,
        order: {},
        loaded_clients: [],
        loaded_teams: [],
        loaded_responsable: [],
        step: 0,
        edit: null,
        delete: null,
        confirmSubmit: null,
        loaded: false,
        visitReport: null,
        state: '',
        description: '',
        cancel_register: '',
        //can_schedule: false,
        showDisponibility: false,
        ...data
    })
    
    useEffect(() => {
        if (data.id)
            loadScheduleRow(data.id, setState, setLoader)
        else
            setState({ loaded: true })
    }, [setLoader, data.id])

    const validData = validateData(state)

    const stepsArray = generateSteps(state, setState, user.permissions, setLoader)
    const maxStep = stepsArray.length - 1

    useEffect(() => {
    }, [setLoader])

    return (
        <>
            {state.showDisponibility &&
                <ShowDisponibility
                    id={state.id}
                    initialDay={state.datetime}
                    duration={state.duration}
                    submitDate={(datetime, duration)=>{setState({datetime: datetime, duration: duration, showDisponibility:false})}}
                    handleClose={() => setState({ showDisponibility: false })}
                />
            }
            {state.confirmSubmit &&
                <ConfirmationDialog
                    title={state.confirmSubmit.title}
                    handleClose={() => setState({ confirmSubmit: null })}
                    onConfirm={state.confirmSubmit.function}
                    okButton={state.confirmSubmit.okButton}
                    content={state.confirmSubmit.content}
                    message={state.confirmSubmit.message}
                />}
            {state.edit && <EditClientModal
                key={`client-modal-key${state.edit?.id}`}
                data={state.edit.data}
                handleClose={() => { setState({ edit: null }) }}
                mode='pop-up'
                types={[['CLIENT', 'CLIENTE']]}
                reloadData={(data) => {
                    const option = state.loaded_clients.find(each => each.id === data.id)
                    const newobj = { label: data.name, id: data.id, data: data }
                    if (!option)
                        state.loaded_clients.push(newobj)
                    else
                        Object.assign(option, newobj)
                    setState({ loaded_clients: state.loaded_clients, client: newobj })
                }}
            />}
            {state.loaded && <DefaultModal
                title={
                    `${getTitle(state)} Visita`
                }
                handleClose={handleClose}
                content={
                    <>
                        <Stepper activeStep={state.step}>
                            {stepsArray.map((step, index) => (
                                <Step key={step.label} >
                                    <StepLabel sx={{
                                        '.MuiStepIcon-root.Mui-active': { color: apptheme.primaryColor },
                                        '.MuiStepIcon-root.Mui-completed': { color: apptheme.primaryColor }
                                    }}>
                                        {step.label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Box className='item-content-modal noborder' sx={{ paddingTop: '1rem' }}>
                            {stepsArray[state.step].component}
                        </Box>
                    </>
                }
                action={
                    <>
                        <Box className='action-left-options'>
                            {(user.permissions.view_client && state.step === 1) &&
                                <ColorButton
                                    onClick={() => { setState({ showDisponibility: true }) }}
                                >
                                    Verificar disponibilidade
                                </ColorButton>}
                            {(user.permissions.add_client && state.step === 0 && state.state === '') &&
                                <ColorButton
                                    onClick={() => { setState({ edit: {} }) }}
                                >
                                    Novo cliente
                                </ColorButton>}
                            {(user.permissions.change_client && state.step === 0 && state.client) && <ColorButton
                                onClick={() => { setState({ edit: state.client }) }}
                            >
                                Editar cliente
                            </ColorButton>}
                            {(state.step === maxStep && state.state === 'SCHEDULED' && user.permissions.add_proposal) &&
                                <ColorButton
                                    onClick={() => setState({
                                        confirmSubmit: {
                                            function: (message) => { cancelSchedule(state, setState, setLoader, reloadData, message); setState({ confirmSubmit: null }) },
                                            title: 'Confirmar ação.',
                                            content: 'Deseja cancelar o agendamento?',
                                            okButton: 'Sim',
                                            message: true
                                        }
                                    })}
                                    sx={{ width: '15rem' }}
                                    disabled={validData}>
                                    Cancelar
                                </ColorButton>}
                        </Box>

                        <Box className='action-right-options'>
                            {['', 'OPEN'].includes(state?.state) &&
                                <Tooltip title='Salvar como rascunho'>
                                    <ColorButton
                                        onClick={() => { submit(state, reloadData, setLoader, handleClose, setState, 'draft') }}
                                        disabled={state.client === ''}
                                    >
                                        Rascunho
                                    </ColorButton>
                                </Tooltip>}
                            {(state.step !== 0 && ['', 'OPEN'].includes(state?.state)) && <ColorButton
                                onClick={() => setState({ step: state.step - 1 })}
                            >
                                Anterior
                            </ColorButton>}
                            <Tooltip placement="left" title={validData}>
                                <Box>
                                    {state.step !== maxStep && <ColorButton
                                        onClick={() => { 
                                            if (state.step === 0) { 
                                                setState({ step: state.step + 1 })
                                            } 
                                            if (state.step === 1) {
                                                disponibilityVerify(state, setLoader, setState)
                                            }
                                        }}
                                        disabled={validData}>
                                        Próximo
                                    </ColorButton>}
                                    {(state.step === maxStep && ['', 'OPEN'].includes(state?.state)) && <ColorButton
                                        onClick={() => setState({
                                            confirmSubmit: {
                                                function: () => { submit(state, reloadData, setLoader, handleClose, setState); setState({ confirmSubmit: null }) },
                                                title: 'Confirmar agendamento.',
                                                content: 'Ao concluir, você não poderá mais editar esse agendamento.',
                                                okButton: 'Confirmar'
                                            }
                                        })}
                                        disabled={validData}>
                                        Concluir
                                    </ColorButton>}
                                </Box>
                            </Tooltip>
                            {(state.step === maxStep && state.state === 'SCHEDULED' && user.permissions.add_proposal) &&
                                <ColorButton
                                    onClick={() => setState({
                                        confirmSubmit: {
                                            function: () => { initProposal(state, openProposal); setState({ confirmSubmit: null }) },
                                            title: 'Confirmar ação.',
                                            content: 'Deseja iniciar a proposta?',
                                            okButton: 'Sim'
                                        }
                                    })}
                                    sx={{ width: '15rem' }}
                                    disabled={validData}>
                                    Iniciar proposta
                                </ColorButton>}
                        </Box>
                    </>
                }
            />}
        </>
    )
}

export default EditScheduleModal