import React, { useContext, useReducer } from 'react'
import DefaultModal from '../../Utils/DefaultModal'
import { lockedReducer } from '../../Utils/reducers'
import { Box, Autocomplete, TextField, CircularProgress, List, ListItem, Divider, Tooltip, ListItemButton, ListItemText } from '@mui/material'
import IntegerOnly from '../../Utils/IntegerOnly'
import timeoutList from '../../Utils/TimeoutList'
import ColorButton from '../../Buttons/ColorButton'
import { getSession } from '../../../contexts/auth'
import { externalComponent } from '../../AppRoutes'
import { validatePaymentData } from './validateData'
import ConfirmationDialog from '../../Utils/ConfirmationDialog'
import { api } from '../../../services/api'
import toast from 'react-hot-toast'
import { maskedTable } from '../../Utils/MaskedInput';
import { NumericFormatInput } from '../../Utils/MaskedInput';
import ServiceSelection from '../Proposal/serviceselection'

const url = '/order/serviceorder/'

const calc_parcels = (proposal) => {
    const entry = Number(proposal.payment_data?.entry_value || 0)
    const total_value = Number(proposal.total_value || 0)
    const parcels = Number(proposal.payment_data?.parcels || 1)
    return maskedTable['value'](Number((total_value - entry) / parcels) || 0)
}

const submit = async (parent, state, reloadData, setLoader, setParent) => {
    setLoader(true)
    const res = await api.post(url + `${parent.id}/done/`, state)
    setLoader(false)
    if (res.status === 200) {
        toast.success(`Ordem de serviço concluída com sucesso`)
        reloadData()
        res.data.teams = res.data.teams_serialized
        setParent({ ...res.data, step: 1, showpostpayment: null })
    }

}

const PostPayment = ({ parent, setParent, reloadData }) => {

    const user = getSession()

    const { setLoader } = useContext(externalComponent)

    const [state, setState] = useReducer(lockedReducer, {
        datetime: parent.datetime,
        payments: [],
        loaded_payments: [],
        additional: 0,
        discount: 0,
        confirmSubmit: null,
        observation_register: '',
        newProducts: false,
        changedServices : null
    })

    const changed_final_value = state.changedServices ? state.changedServices.map(p => Number(p.value)).reduce((x,y) => x+y, 0) : null
    const subtotal =  changed_final_value || Number(parent.total_value)
    const total_value = subtotal + Number(state.additional) - Number(state.discount)

    const paid_value = state.payments.filter(each => Number(each.value)).map(
        each => Number(each.value) / 1)
        .reduce((a, b) => a + b, 0)


    const validData = validatePaymentData(state, total_value, paid_value)

    return (
        <>
            {state.newProducts &&
                <ServiceSelection
                    handleClose={() => setState({ newProducts: false })}
                    recallData={parent.id}
                    submit={(data) => setState({ newProducts: false, changedServices: data })} 
                    changedServices={state.changedServices}
                    />
            }
            {state.confirmSubmit &&
                <ConfirmationDialog
                    title={state.confirmSubmit.title}
                    handleClose={() => setState({ confirmSubmit: null })}
                    onConfirm={state.confirmSubmit.function}
                    okButton={state.confirmSubmit.okButton}
                    content={state.confirmSubmit.content}
                />}

            <DefaultModal
                handleClose={() => setParent({ showpostpayment: null })}
                title='Confirmar conclusão de OS'
                content={
                    <>
                        <List className='proposal-resume-list'>
                            <ListItemButton>
                                {!state.changedServices? <ListItemText primary='Resumo' secondary={<Box sx={{ display: 'flex', gap: '.5rem', flexDirection: 'column' }}>
                                    {(parent.proposal?.payment_data?.entry_value && parent.proposal?.payment_data?.entry_value > 0) &&
                                        <Box>Entrada: {maskedTable['value'](Number(parent.proposal?.payment_data?.entry_value))}</Box>
                                    }
                                    <Box>{parent.proposal.payment.label}: {Number(parent.proposal?.payment_data?.parcels || 1)}x {calc_parcels(parent.proposal)}</Box>
                                </Box>} />
                            :
                                <ListItemText primary='Resumo' secondary={'Produtos alterados'} />
                            }
                            </ListItemButton>
                        </List>
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <TextField
                                id={'acres'}
                                type="text"
                                sx={{ backgroundColor: 'white', flex: 1 }}
                                label="Acréscimo"
                                size="small"
                                fullWidth={true}
                                value={state.additional}
                                onChange={(e) => setState({ additional: e.target.value })}
                                inputProps={{
                                    maxLength: 13
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatInput
                                }}

                            />
                            <TextField
                                id={'desc'}
                                type="text"
                                sx={{ backgroundColor: 'white', flex: 1 }}
                                label="Desconto"
                                size="small"
                                fullWidth={true}
                                value={state.discount}
                                onChange={(e) => setState({ discount: e.target.value })}
                                inputProps={{
                                    maxLength: 13
                                }}
                                InputProps={{
                                    inputComponent: NumericFormatInput
                                }}
                            />
                        </Box>
                        <Divider sx={{ margin: '0.5rem 0' }} />
                        <Box sx={{ display: 'flex', gap: '1rem' }}>
                            <Autocomplete
                                multiple
                                sx={{ flex: 1 }}
                                id={'autocompletebox-payments'}
                                value={state.payments}
                                options={state.loaded_payments.filter(each => !state.payments.map(e => e.id).includes(each.id))}
                                onChange={(e, selectedPayments) => { setState({ payments: selectedPayments }) }}
                                autoHighlight
                                handleHomeEndKeys
                                clearText='Limpar'
                                noOptionsText='Digite para pesquisar Formas de pagamento'
                                loading={state.smallLoading}
                                ChipProps={{ size: 'small' }}
                                filterSelectedOptions
                                onOpen={() => state.loaded_payments.length === 0 && timeoutList('', 'order/serviceorder/payments/', 'loaded_payments', 'name', setState)}
                                onInputChange={(e, v) => { e?.type === 'change' && timeoutList(v, 'order/serviceorder/payments/', 'loaded_payments', 'name', setState) }}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        id={'autocompleteinput'}
                                        type="text"
                                        sx={{ backgroundColor: 'white' }}
                                        label="Formas de pagamento"
                                        size="small"
                                        fullWidth={true}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    {state.smallLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                }
                            />
                        </Box >
                        {state.payments.length > 0 &&
                            <>
                                <List className='payment-service-list'>
                                    {state.payments.map((payment, index) => (
                                        <ListItem disablePadding sx={{ gap: '1rem', paddingTop: '.5rem' }} key={`payment-listitem${payment.id}`} title={payment.label}>
                                            <Box className='simple-label'>
                                                <Box>{payment.label}</Box>
                                                <Box className='single-line'></Box>
                                            </Box>
                                            <Box sx={{ flex: .5, display: 'flex' }}>
                                                <TextField
                                                    sx={{ flex: 1 }}
                                                    size='small'
                                                    label='Valor.'
                                                    value={payment.value}
                                                    inputProps={{
                                                        maxLength: 13
                                                    }}
                                                    InputProps={{
                                                        inputComponent: NumericFormatInput
                                                    }}
                                                    onChange={(e) => { payment.value = e.target.value; setState({ payments: [...state.payments] }) }} />
                                                {payment.data.parcel_count > 1 && <TextField
                                                    size='small'
                                                    label='Parcelas'
                                                    sx={{ flex: .7 }}
                                                    value={payment.parcels === undefined ? (payment.parcels = payment.data.parcel_count || 1) : payment.parcels}
                                                    inputProps={{
                                                        maxLength: 2
                                                    }}
                                                    onChange={(e) => { payment.parcels = IntegerOnly(e.target.value, 1, payment.data.parcel_count); setState({ payments: [...state.payments] }) }}
                                                />}
                                            </Box>
                                        </ListItem>
                                    ))}

                                </List>
                            </>}
                        <Box sx={{ textAlignLast: 'end', paddingTop: '1rem' }}>Valor da OS: {maskedTable['value'](subtotal)}</Box>
                        <Box sx={{ textAlignLast: 'end' }}>Desconto: {maskedTable['value'](state.discount)}</Box>
                        <Box sx={{ textAlignLast: 'end' }}>Acréscimo: {maskedTable['value'](state.additional)}</Box>
                        <Box sx={{ textAlignLast: 'end' }}>Valor final: {maskedTable['value'](total_value)}</Box>
                        <Box sx={{ textAlignLast: 'end' }}>Valor pago: {maskedTable['value'](paid_value)}</Box>

                        <TextField
                            multiline
                            fullWidth
                            sx={{ marginTop: '1rem' }}
                            maxRows={4}
                            size='small'
                            value={state.observation_register}
                            onChange={(e) => setState({ observation_register: e.target.value })}
                            inputProps={{ maxLength: 5000 }}
                            label='Observação'
                        />
                    </>
                }
                action={
                    <>
                        {(user.permissions.change_serviceorder) &&
                            <span>
                                <ColorButton
                                    onClick={() => setState({ newProducts: true })}
                                >
                                    Alterar produtos
                                </ColorButton>
                            </span>}
                        {(user.permissions.change_serviceorder) && <Tooltip title={validData}>
                            <span>
                                <ColorButton
                                    onClick={() => setState({
                                        confirmSubmit: {
                                            function: () => { submit(parent, state, reloadData, setLoader, setParent); setState({ confirmSubmit: null }) },
                                            title: 'Confirmar ação.',
                                            content: 'Confirmar conclusão da ordem de serviço?',
                                            okButton: 'Sim'
                                        }
                                    })}
                                    disabled={validData}>
                                    Concluir
                                </ColorButton>
                            </span></Tooltip>}
                    </>
                }
            />
        </>
    )
}

export default PostPayment