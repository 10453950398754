import React, { useEffect, useReducer } from "react"
import { Box, Select, TextField, MenuItem, FormControl } from "@mui/material"
import { lockedReducer } from "../../Utils/reducers"
import { capitalize } from "../../Utils/functions"
import { NumbersOnly } from "../../Utils/IntegerOnly"
import { useGridApiContext } from "@mui/x-data-grid"
import { NumericFormatInput } from "../../Utils/MaskedInput"

const operations = {
    'equals': 'Igual',
    'gt': 'Maior',
    'gte': 'Maior igual',
    'lt': 'Menor',
    'lte': 'Menor igual'
}

const Internal = ({ filterFields, state, setState, props }) => {
    return (
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <FormControl sx={{ flex: .4 }} size='small' >
                <Select
                    id={`selected-${props.field}-stringfilterfield`}
                    key={`selected-${props.field}-stringfilterfield`}
                    value={state.selector}
                    onChange={(e) => {
                        setState({ 'selector': e.target.value })
                        filterFields[props.field].filterField = state.field
                        filterFields[props.field].selectField = e.target.value
                    }}
                >
                    {Object.entries(operations).map(([key, value]) =>
                        <MenuItem value={key}>{capitalize(value)}</MenuItem>
                    )}
                </Select>
            </FormControl>
            <TextField
                sx={{ flex: .6 }}
                fullWidth
                size='small'
                label={props.headerName}
                value={state.field}
                onChange={e => {
                    const value = e.target.value
                    setState({ field: value })
                    filterFields[props.field].filterField = value
                    filterFields[props.field].selectField = state.selector
                }}
                // InputProps={{
                //     inputComponent: NumericFormatInput
                // }}
            />
        </Box>
    )
}

export const NumberFilterMobile = (props, parent) => {

    const filterFields = parent.filterModel.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'equals'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'equals' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props} />
    )
}

const NumberFilter = (props) => {

    const apiRef = useGridApiContext();

    const filter = apiRef.current.state.filter
    const filterFields = filter.filterFields

    const [state, setState] = useReducer(lockedReducer, {
        field: filterFields?.[props.field]?.filterField || '',
        selector: filterFields?.[props.field]?.selectField || 'equals'
    })

    useEffect(() => {
        if (filterFields && props.field in filterFields) {
            setState({ field: filterFields[props.field].filterField || '', selector: filterFields[props.field].selectField || 'equals' })
            return
        }
    }, [filterFields, props.field])

    return (
        <Internal filterFields={filterFields} state={state} setState={setState} props={props} />
    )
}

export default NumberFilter